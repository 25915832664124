<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="500"
    :loading="loading"
    @close="onClose"
    @ok="onSubmit((e) => onSubmitFun(e))"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-input
          :labelCol="5"
          :valueCol="19"
          title="菜品名称"
          :validate="validateInfos.name"
          v-model:value="modelRef.name"
        />

        <e-cascader
          :labelCol="5"
          :valueCol="19"
          title="菜品类型"
          :options="cateOptions"
          :validate="validateInfos.dishTypeId"
          v-model:value="parentIds"
          @ok="changeDishType"
        />

        <e-input-upload
          :labelCol="5"
          :valueCol="19"
          title="菜品图片"
          v-model:value="modelRef.pic"
        />
        <a-row style="padding-left: 2px">
          <a-col :span="12">
            <e-input-number
              :labelCol="10"
              :valueCol="14"
              title="成本价"
              v-model:value="modelRef.price"
            />
          </a-col>
          <a-col :span="12">
            <e-input-number
              :labelCol="10"
              :valueCol="14"
              title="售价"
              v-model:value="modelRef.sellingPrice"
            />
          </a-col>
        </a-row>

        <a-row style="padding-left: 2px">
          <a-col :span="12">
            <e-input-number
              :labelCol="10"
              :valueCol="14"
              title="毛利率/%"
              v-model:value="modelRef.grossMargin"
            />
          </a-col>

          <a-col :span="12">
            <e-input-number
              :labelCol="10"
              :valueCol="14"
              title="推荐顺序"
              v-model:value="modelRef.recommendedSortNum"
            />
          </a-col>
        </a-row>

        <a-row style="padding-left: 2px">
          <a-col :span="12">
            <e-input
              :labelCol="10"
              :valueCol="14"
              title="单位"
              v-model:value="modelRef.unit"
            />
          </a-col>
          <a-col :span="12">
            <e-input
              title="味型"
              :labelCol="10"
              :valueCol="14"
              v-model:value="modelRef.tasteType"
            />
          </a-col>
        </a-row>

        <e-input
          :labelCol="5"
          :valueCol="19"
          title="标签"
          v-model:value="modelRef.label"
        />

        <e-input
          :labelCol="5"
          :valueCol="19"
          title="介绍文字"
          v-model:value="modelRef.brief"
        />

        <a-row style="padding-left: 2px">
          <a-col :span="12">
            <e-switch
              :labelCol="10"
              :valueCol="14"
              title="是否上架"
              v-model:value="modelRef.isOn"
            />
          </a-col>
          <a-col :span="12">
            <e-switch
              :labelCol="10"
              :valueCol="14"
              title="是否必选"
              v-model:value="modelRef.isMust"
            />
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'
import { DishClass } from '@/apis/dish'
import { dataToCascader, getParentIdArray } from '@/utils'

const useForm = Form.useForm
const api = new DishClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {},
  props: {
    storeId: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const isEdit = ref(false)
    const title = ref('')
    const categorys = ref([])
    const parentIds = ref([])

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()
      parentIds.value = []
      loading.value = false
      visible.value = false
    }

    const cateOptions = ref([])

    return {
      title,
      visible,
      loading,
      categorys,
      parentIds,
      isEdit,
      modelRef,
      validateInfos,
      cateOptions,
      resetFields,
      validate,
      onClose
    }
  },

  methods: {
    open (formData) {
      if (formData) {
        this.modelRef.id = formData.id
        this.modelRef.name = formData.name
        this.modelRef.dishTypeId = formData.dishTypeId
        this.modelRef.pic = formData.pic
        this.modelRef.price = formData.price
        this.modelRef.sellingPrice = formData.sellingPrice
        this.modelRef.isOn = formData.isOn
        this.modelRef.unit = formData.unit
        this.modelRef.isPersonal = formData.isPersonal
        this.modelRef.isMust = formData.isMust
        this.modelRef.type = formData.type
        this.modelRef.tasteType = formData.tasteType
        this.modelRef.recommendedSortNum = formData.recommendedSortNum
        this.modelRef.grossMargin = formData.grossMargin
        this.modelRef.brief = formData.brief
        this.modelRef.label = formData.label
        this.modelRef.dishGoodsItems = formData.dishGoods

        this.title = '编辑：' + formData.name

        this.getCategorys()
        this.visible = true
      }
    },

    changeDishType (e) {
      this.modelRef.dishTypeId = e[e.length - 1]
    },
    changePrice () {
      this.isChanged = true
      this.modelRef.price = this.stockPrice
    },

    async getCategorys () {
      const list =
        this.$store.state.data.ALL_DISHTYPES.length > 0
          ? this.$store.state.data.ALL_DISHTYPES
          : await this.$store.dispatch('GetAllDishTypes')

      this.cateOptions = dataToCascader(list, 0)

      if (this.modelRef.dishTypeId) {
        console.log(this.modelRef.dishTypeId)
        const parentIds = getParentIdArray(
          toRaw(list),
          this.modelRef.dishTypeId
        )
        parentIds.sort((a, b) => a - b)
        this.parentIds = parentIds.filter((x) => x > 0)
      }
    },

    onSubmitFun () {
      this.validate()
        .then(() => {
          const data = toRaw(this.modelRef)

          api
            .update(data)
            .then(() => {
              this.loading = false
              this.$message.success('编辑成功')
              this.$emit('ok')

              this.onClose()
            })
            .catch((err) => {
              this.loading = false
              this.$message.error('编辑失败')
              console.log('error', err)
            })
        })
        .catch((e) => {
          this.loading = false
          e.errorFields.map((e) => {
            e.errors.map((x) => {
              this.$message.error(x)
            })
          })
        })
    }
  }
})
</script>

<style lang="less" scoped></style>
